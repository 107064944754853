import translateService from './../services/translateService';

export const initialData = {
    currentLang: translateService.key,
    facsimile: '',
    isFacsimileDataSending: false,
    showFacsimileModal: false,
    signatureProcessStep: 1,
    faximCardInitialized: false,
    activeFacsimileIndex: 1,
    pdfData: null,
    pdfPath: '',
    hasFacsimileError: false,
    isEditing: false,
    useFacsimile: 0,
    facsimData: null,
    isBase64Fetching: false,
    fromDashboard: false,
    activeDomain: null,
    setState: () => {},
    endSugnatureProcess: () => {},
    resetFacsimileStates: () => {}
};