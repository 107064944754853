import React, { useEffect, useState, useRef } from "react";

const Loading = ({ stop }) => {
  const [isLoading, setIsLoading] = useState(true);
  const timeRef = useRef();

  useEffect(() => {
    if (!stop) {
      timeRef.current = setTimeout(() => {
        setIsLoading(false);
        if (timeRef.current) clearTimeout(timeRef.current);
      }, 1000);
    }
  }, [stop]);

  return isLoading ? (
    <div className="pdfLoading">
      <img src="./../../../assets/images/black-loading.svg" />
    </div>
  ) : null;
};

export default React.memo(Loading);
