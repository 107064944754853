import React from "react";
import OptimizedComponent from "./optimizedComponent";
import {
  routingService,
  authService,
  translateService,
  stateService,
  commonService,
  workSpacesService,
  htmlHelperService,
} from "../services/imports";
import { defaults, states } from "../models/imports";
import { stateEvents } from "./../models/states";
import DropDown from "../components/dropDown";
import FacsimileEditor from "./facsimileEditor";
import {AppContext} from './../Context/AppContext';

export default class PageHeader extends OptimizedComponent {
  constructor(props) {
    super(props);
    this.state = {
      userName: null,
      languagesData: [],
      domains: [],
      activeLang: {
        value: defaults.locale.value,
        key: defaults.locale.key,
      },
      workSpaceId: null,
      isLoading: true,
    };

    this.subscriptions = [];
  }

  static contextType = AppContext;

  logout = (e) => {
    e.preventDefault();

    this.secureSubscription(authService.signOut());
    routingService.push(defaults.notAuthRoute);
  };

  setLang = (lang) => {
    translateService.use(lang.id, lang.key, translateService.changeUserLocale);
    this.setState((prevState) => {
      let activeLang = { ...prevState.activeLang };
      activeLang.value = lang.value;
      activeLang.key = lang.key;

      return { ...prevState, activeLang };
    });
  };

  setDomain = (domain) => {
    this.context.setState({activeDomain: domain.value });
    stateService.event(states.changeWorkSpace, domain.id);
    let userInfo = { ...this.state.userInfo };
    userInfo.workspaceId = domain.id;
    stateService.setState(states.userInfo, userInfo);
  };

  openModalPage = () => {
    const type =
      authService.isAuthenticated() && authService.isActive()
        ? routingService.routeNames.Settings
        : routingService.routeNames.packages;
    stateService.event(stateEvents.openModalPage, type);
  };

  goHome = () => {
    this.fetchWorkSpaces(true);
    routingService.navigate("/");
    stateService.event(stateEvents.openModalPage, null);
  };

  fetchWorkSpaces(renew){
    if ((authService.isAuthenticated() && authService.isActive() && !this.state.domains.length) || (renew && authService.isAuthenticated() && authService.isActive())) {
      this.secureSubscription(
        workSpacesService.getWorkSpaces().subscribe((res) => {
          this.setState(
            (prevState) => {
              let domains = res.data;

              let activeDomain = [...domains].filter(
                (w) => w.id === prevState.workSpaceId
              );

              if (activeDomain.length)
                activeDomain = activeDomain[0].value;

                this.context.setState({activeDomain: activeDomain });
              return {
                ...prevState,
                domains,
                isLoading: false,
              };
            }
          );
        })
      );
    } else {
      this.setState({ isLoading: false });
    }
  }

  componentDidMount() {
    this.subscriptions.push(stateService.onEvent(stateEvents.logouted).subscribe(() => {
      this.context.endSugnatureProcess();
      })
    );

    this.secureSubscription(
      stateService.getState(states.allLocales).subscribe((data) => {
        if (!data?.allLocales) {
            return;
        }
        const locales = data.allLocales;
        this.setState({ languagesData: locales }, () => {
          this.secureSubscription(
            stateService.getState(states.userInfo).subscribe((data) => {
              this.setState(
                (prevState) => {
                  let activeLang = { ...prevState.activeLang };

                  let curLang = this.state.languagesData?.filter(
                    (l) => l.id == data.localeId
                  );
                  if (curLang.length) {
                    curLang = curLang[0];
                    activeLang.value = curLang.value;
                    activeLang.key = curLang.key;
                  } else {
                    activeLang.value = defaults.locale.value;
                    activeLang.key = defaults.locale.key;
                  }
                  let workSpaceId = data.workspaceId;
                  let userName = data.mail;
                  let userInfo = { ...data };
               
                  this.context.setState({useFacsimile: data.useFacsimile});
                  this.context.setState({facsimile: data.facsimilePath});
                  return {
                    ...prevState,
                    activeLang,
                    workSpaceId,
                    userName,
                    userInfo,
                    isLoading: true,
                  };
                },
                () => {
                  this.fetchWorkSpaces(false);
                }
              );
            })
          );
        });
      })
    );
  }

  /* facsimile */

  startSignatureEditing = () => {
    this.context.setState({showFacsimileModal: true, fromDashboard: false, faximCardInitialized: true, isEditing: this.context.state.useFacsimile > 0});
    // htmlHelperService
    //   .setIdleTimer(10)
    //   .subscribe(() => {
    //     this.context.setState({faximCardInitialized: true, isEditing: this.context.state.useFacsimile > 0});
    //   })
    //   .unsubscribe();
  };

  resetFacsimileStates = () => {
    this.context.setState({activeFacsimileIndex: 1, facsimile: null, isEditing: false, fromDashboard: false});
  };

  componentDidUpdate() {
    if(!authService.isAuthenticated() && this.state.domains?.length) {
    this.setState({domains: []});
    }
  }

  render() {
    const languages = (
      <div className="dropDown">
        {this.state.languagesData &&
          this.state.languagesData.map(
            (lang) =>
              lang.value != this.state.activeLang.value && (
                <div
                  key={lang.id}
                  onClick={() => this.setLang(lang)}
                  className={`dropDownItem ${
                    this.state.activeLang.value == lang.value ? "active" : ""
                  }`}
                >
                  <img src={`../../assets/images/${lang.key}.svg`} />
                  {lang.value}
                </div>
              )
          )}
      </div>
    );

    const domains =
      (this.state.domains?.length == 1 &&
        this.state.domains[0]?.id == this.state.workSpaceId) ||
      !authService.isAuthenticated() ||
      !authService.isActive() ||
      this.state.isLoading ? null : (
        <div className="dropDown">
          {this.state.domains.map((domain) => (
            <div
              key={domain.id}
              onClick={() => this.setDomain(domain)}
              className={`dropDownItem ${
                this.context.state.activeDomain == domain.value ? "active" : ""
              }`}
            >
              {commonService.toShortCode(domain.value, 8)}
            </div>
          ))}
        </div>
      );

    return (
      <header className={`header ${translateService.key}`}>
        <div className="header-item-left">
          <div className="logo clickable" onClick={this.goHome}>
            <img src="../../assets/images/logo.svg" className="for-web" />
            <img
              src="../../assets/images/logo-for-small-devices.svg"
              className="for-mobile"
            />
          </div>
          <div
            className={`title ${
              authService.isActive() && authService.isAuthenticated()
                ? "for-web"
                : ""
            }`}
          >
            {translateService.t("common.defaultTitle")}
          </div>
        </div>
        <div className="header-item-right">
          {domains && (
            <div className="account-switcher">
              <DropDown
                current={commonService.toShortCode(this.context.state.activeDomain, 8)}
                tapImg="../../assets/images/updownarrow.svg"
                className="dropDownComponent"
                activeClassName="active"
                tapClassName="toggle clickable"
              >
                {domains}
              </DropDown>
            </div>
          )}
          {authService.isAuthenticated() &&
            authService.isActive() &&
            this.state.userName && (
              <div className="userEmail">
                <span>{this.state.userName}</span>
                <img src="../../assets/images/man.svg" />
              </div>
            )}
          <div className="lang-switcher">
            <DropDown
              current={
                <div className="activeLang">
                  {" "}
                  <img
                    src={`../../assets/images/${this.state.activeLang.key}.svg`}
                  />
                </div>
              }
              className="dropDownComponent"
              tapClassName="toggle clickable"
            >
              {languages}
            </DropDown>
          </div>
          {authService.isAuthenticated() && authService.isActive() && (
            <div className="hamburger-menu clickable">
              <DropDown
                current={this.state.activeMenu}
                tapImg="../../assets/images/hamburger.svg"
                className="dropDownComponent"
                tapClassName="toggle clickable"
              >
                <div className="dropDown">
                  <div className="dropDownItem" onClick={this.openModalPage}>
                    {translateService.t("common.settings")}
                  </div>
                  <div
                    className="dropDownItem"
                    onClick={this.startSignatureEditing}
                  >
                    {translateService.t("common.facsimile")}
                  </div>
                  <div className="dropDownItem" onClick={this.logout}>
                    {translateService.t("common.logout")}
                  </div>
                </div>
              </DropDown>
            </div>
          )}
        </div>
        {!this.context.state.fromDashboard && <FacsimileEditor
          isEditing = {this.context.state.useFacsimile > 0}
          onClosed={this.resetFacsimileStates}
        />}
      </header>
    );
  }
}
