import React, { createContext, useState, useMemo } from "react";
import { initialData } from "./AppState";

export const AppContext = createContext(initialData);

const CreateAppContext = ({ children }) => {
  const [appState, setAppState] = useState(initialData);

  const setState = (updateValue) => { 
    setAppState((prevState) => ({
      ...prevState,
      ...updateValue,
    }));
  };

  const resetFacsimileStates = () => {
    setState({isBase64Fetching: false, facsimData: null, signatureProcessStep: 1, activeFacsimileIndex: 1, hasFacsimileError: !appState.hasFacsimileError, showFacsimileModal: false, fromDashboard: false});
  };

  const endSugnatureProcess = () => {
    setState({showFacsimileModal: false});
    setTimeout(() => {
        resetFacsimileStates();
    }, 500);
  };

  const value = useMemo(() => ({ state: appState, setState, endSugnatureProcess, resetFacsimileStates }), [appState]);

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export default CreateAppContext;
