import React, {
  useEffect,
  useRef,
  createRef,
  useState,
  useContext,
} from "react";
import {
  translateService,
  mediaService,
  htmlHelperService,
  stateService,
} from "./../services/imports";
import {
  createValidationContext,
  Input,
  LoadingButton,
  FieldValidation,
} from "./../components/formcontrols/imports";
import PdfSign from "../containers/PdfSignature/PdfSign";
import { stateEvents, states } from "../models/states";
import { AppContext } from "./../Context/AppContext";
import { Scrollbars } from "react-custom-scrollbars";

const FacsimileCard = (props) => {
  const canvasRef = createRef();
  const [fontInitialize, setFontInitialized] = useState(false);
  const { state } = useContext(AppContext);
  const { currentLang } = state;

  const fonts = {
    ka: [
      "mzeqala-regular",
      "gl-erekles-stamba-regular",
      "dm-niko-nikoladze",
      "sylfaen",
    ],
    en: [
      "AlexBrush-Regular",
      "Miama",
      "mzeqala-regular",
      "gl-erekles-stamba-regular",
    ],
  };

  const getData = () => {
    let data = canvasRef.current?.toDataURL();
    if (data) props.onGetData(data, props.index);
  };

  useEffect(() => {
    htmlHelperService
      .setIdleTimer(500)
      .subscribe(() => {
        setFontInitialized(true);
      })
      .unsubscribe();
  }, []);

  useEffect(() => {
    if (fontInitialize && props.index === 1) {
      htmlHelperService
        .setIdleTimer(500)
        .subscribe(() => {
          getData();
        })
        .unsubscribe();
    }
  }, [fontInitialize, props.text]);

  useEffect(() => {
    let canvas = canvasRef.current;
    let ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    let base_image = new Image();

    base_image.onload = function () {
      ctx.drawImage(base_image, 0, 0);
      ctx.font = `22px ${fonts[currentLang][props.fontType]}`;
      ctx.fillStyle = "#18181D";
      ctx.textAlign = "center";
      ctx.fillText(props.text, canvas.width / 2, 44);
    };
    base_image.src = "./../../../assets/images/facsimbg.svg";
  }, [props.text, fontInitialize]);

  return (
    <div
      className={`facsimCard ${props.active && "active"} ${
        props.index === 0 && "vh"
      }`}
      onClick={getData}
    >
      <canvas
        ref={canvasRef}
        className="facsimCanvas"
        width="260"
        height="80"
      ></canvas>
    </div>
  );
};

const FacsimileEditor = (props) => {
  const [facsimDataObj, setFacsimDataObj] = useState();
  const assignerName = useRef();
  const assignerNameValidation = useRef();
  const [assignerNameValue, setAssignerNameValue] = useState();
  const [facsimileUploadProcessing, setFacsimileUploadProcessing] =
    useState(false);

  const { state, endSugnatureProcess, setState } = useContext(AppContext);
  const {
    isFacsimileDataSending,
    showFacsimileModal,
    fromDashboard,
    signatureProcessStep,
    faximCardInitialized,
    facsimile,
    isEditing,
    activeFacsimileIndex,
    facsimData,
    useFacsimile,
  } = state;

  const SHTAPM_TYPES = [{ type: 1 }, { type: 2 }, { type: 3 }, { type: 4 }];

  const updateErrorInfo = async (e) => {
    !e.target.value && e.target.classList.add("tooltip-error");
  };

  const callBeforeClosing = () => {
    setAssignerNameValue("");
  };

  const sendFacsimData = (data) => {
    setState({ useFacsimile: (useFacsimile || 0) + 1 });
    if (useFacsimile || !fromDashboard) endSugnatureProcess();
    else {
      setState({ signatureProcessStep: 2, isEditing: false });
    }
    setState({ isEditing: false });
    const userData = { ...stateService.getState(states.userInfo).value };
    userData.facsimilePath = data;
    userData.useFacsimile = userData.useFacsimile || 1;
    stateService.setState(states.userInfo, userData);
  };

  const facsimileSetApos = () => {
    let anvv = assignerNameValidation.current
      .removeCustomErrors()
      .setDirty()
      .isValid();

    if (!anvv) {
      return;
    }

    setFacsimileUploadProcessing(true);

    mediaService.uploadFacsimileFile(facsimData).subscribe({
      next: (Response) => {
        if (Response.success) {
          sendFacsimData(Response.data?.value);
          setFacsimileUploadProcessing(false);
        }
      },
      error: (error) => {
        if (error.response.status === 401) {
          stateService.event(stateEvents.logouted, true);
        }
        setFacsimileUploadProcessing(false);
      },
    });
  };

  useEffect(() => {
    assignerNameValidation.current = createValidationContext();
  }, []);

  const handleScroll = (mode) => {
    var elements = document.querySelectorAll("body > *");
    if (elements.length) {
      try {
        for (let a = 0; a < elements.length; a++) {
          elements[a].style.overflowY = mode;
        }
      } catch (_) {}
    }
  };

  useEffect(() => {
    if (!showFacsimileModal) {
      callBeforeClosing();
      handleScroll("auto");
    } else {
      handleScroll("hidden");
    }
  }, [showFacsimileModal]);

  const editing = isEditing;

  return (
    showFacsimileModal && (
      <div
        onClick={endSugnatureProcess}
        className={`signmodal modal-content noheight name-assign-modal ${
          signatureProcessStep === 2 && "stepTwo"
        } ${
          ((signatureProcessStep === 1 && !editing) ||
            signatureProcessStep === 1) &&
          "inFacsimCreation"
        }`}
      >
        <div className="scr">
          <div className="modal-relative" onClick={(e) => e.stopPropagation()}>
            {signatureProcessStep === 1 && (
              <div
                className={`modal-header ${
                  editing && "facsimileEditModalHeader"
                }`}
              >
                {editing && (
                  <>
                    <div className="currentFacsimile">
                      <span className="title">
                        {translateService.t("dashboard.currentFacsimileTitle")}
                      </span>
                      <img
                        src={facsimile}
                        width="260"
                        height="80"
                        className="facsimImg"
                      />
                    </div>

                    <span>
                      &nbsp;
                      <span onClick={endSugnatureProcess} className="clickable">
                        <img src="../../assets/images/close32.svg" />
                      </span>
                    </span>
                  </>
                )}
              </div>
            )}
            {signatureProcessStep === 2 && (
              <div className={`modal-header`}>
                {editing && (
                  <div className="currentFacsimile">
                    <span className="title">
                      {translateService.t("dashboard.currentFacsimileTitle")}
                    </span>
                    <img
                      src={props.currentFacsimData}
                      width="260"
                      height="80"
                      className="facsimImg"
                    />
                  </div>
                )}
                <span>
                  &nbsp;
                  <span onClick={endSugnatureProcess} className="clickable">
                    <img src="../../assets/images/close32.svg" />
                  </span>
                </span>
              </div>
            )}
            <div className="modal-body">
              {signatureProcessStep === 1 && (
                <Scrollbars
                  autoHide
                  className={`facsimileScrollVIew ${
                    signatureProcessStep === 1 && editing && "isEditing"
                  } ${signatureProcessStep === 1 && !editing && "isCreating"}`}
                >
                  <div className="fontModalContent">
                    <div className="fontModalTitle">
                      {translateService.t(`dashboard.facsimileBlockTitle`)}

                      {signatureProcessStep === 1 && !editing && (
                        <span
                          onClick={endSugnatureProcess}
                          className="clickable"
                        >
                          <img src="../../assets/images/close32.svg" />
                        </span>
                      )}
                    </div>
                    <div className="input-item assignerInput">
                      <FieldValidation
                        name="assignerName"
                        className="validation-register"
                        rule="required"
                        validation={assignerNameValidation.current}
                        for={assignerName.current}
                      />
                      <div
                        className={`assign ${
                          assignerNameValue ? "active" : ""
                        }`}
                      >
                        <Input
                          name="assignerName"
                          type="text"
                          placeholder={translateService.t(
                            "dashboard.namelname"
                          )}
                          spellCheck="false"
                          autoComplete="off"
                          value={assignerNameValue || ""}
                          rules={[{ name: "required" }]}
                          validation={assignerNameValidation.current}
                          maxLength={31}
                          onChange={(e) => {
                            setAssignerNameValue(e.target.value);
                          }}
                          ref={assignerName.current}
                          onFocus={(e) => updateErrorInfo(e)}
                        />
                        {true && <button className="emtyLink"></button>}
                      </div>
                    </div>

                    <div className="fontModalTitle fontModalChildTitle">
                      {translateService.t(`dashboard.choosefacsimile`)}
                    </div>

                    <div className="facsimContainer">
                      {!faximCardInitialized && (
                        <div className="pdfLoading">
                          <img src="./assets/images/black-loading.svg" />
                        </div>
                      )}
                      <FacsimileCard
                        text={""}
                        active={false}
                        fontType={0}
                        index={0}
                        onGetData={() => {}}
                      />
                      {SHTAPM_TYPES.map((s) => (
                        <FacsimileCard
                          text={
                            assignerNameValue ||
                            translateService.t("dashboard.namelname")
                          }
                          active={activeFacsimileIndex === s.type}
                          key={s.type}
                          fontType={s.type - 1}
                          index={s.type}
                          onGetData={(data, index) =>
                            setState({
                              facsimData: data,
                              activeFacsimileIndex: index,
                            })
                          }
                        />
                      ))}
                    </div>
                    <div
                      className={`buttons ${
                        (editing || !fromDashboard) && "twoItem"
                      }`}
                    >
                      <LoadingButton
                        className="button background-color-three color-white"
                        onClick={endSugnatureProcess}
                      >
                        {translateService.t("common.cancell")}
                      </LoadingButton>

                      {!editing && fromDashboard && (
                        <LoadingButton
                          className="button background-color-two color-white"
                          onClick={props.OnValidateWithoutApostile}
                        >
                          {translateService.t("dashboard.withoutApost")}
                        </LoadingButton>
                      )}

                      <LoadingButton
                        className="button background-color-two color-white"
                        loading={facsimileUploadProcessing}
                        onClick={facsimileSetApos}
                      >
                        {translateService.t(
                          `common.${editing ? "submit" : "continue"}`
                        )}
                      </LoadingButton>
                    </div>
                  </div>
                </Scrollbars>
              )}
              {signatureProcessStep === 2 && !editing && (
                <PdfSign
                  facsimile={facsimData || facsimile}
                  onSubmitFacsimData={setFacsimDataObj}
                  onClose={endSugnatureProcess}
                />
              )}
              <div className="modal-footer">
                <div className="modalfooter">
                  <div className="buttons">
                    <LoadingButton
                      className="button background-color-three color-white"
                      onClick={endSugnatureProcess}
                    >
                      {translateService.t("common.cancell")}
                    </LoadingButton>

                    <LoadingButton
                      className="button background-color-two color-white"
                      onClick={props.OnValidateWithoutApostile}
                    >
                      {translateService.t("dashboard.withoutApost")}
                    </LoadingButton>

                    <LoadingButton
                      className="button background-color-two color-white"
                      loading={isFacsimileDataSending}
                      onClick={() => props.onSubmitFacsimData(facsimDataObj)}
                    >
                      {translateService.t("common.continue")}
                    </LoadingButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default FacsimileEditor;
