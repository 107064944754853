import axios from 'axios';
import { from } from 'rxjs';


class MediaService {
    uploadFile(file, mail) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('mail', mail);

        let promise = axios.post(`${globalConfig.api_URL}api/public/freedocumentvalidation`, formData, {
            objectResponse: true,
            headers: { 'Content-Type': 'multipart/form-data' }
        });
        return from(promise);
    }

    privateUploadFile(file, docLink, workSpaceId, withSignature = false, signParameter = undefined, docId = 0) {
        const formData = new FormData();
        file && formData.append('file', file);
        docId && formData.append('docId', docId);
        (docLink) && formData.append('doclink', docLink);
        formData.append('workspaceId', workSpaceId);
        signParameter && formData.append('signParameter', JSON.stringify(signParameter));
        withSignature && formData.append('withSignature', withSignature);

        let promise = axios.post(`${globalConfig.api_URL}api/private/validatedocument`, formData, {
            objectResponse: true,
            headers: { 'Content-Type': 'multipart/form-data' }
        });
        return from(promise);
    }

    shareDocument(mail, docId) {
        let promise = axios.post(`${globalConfig.api_URL}api/private/sharedocument/${mail}/${docId}`, {}, { objectResponse: true });
        return from(promise);
    }

    uploadFacsimileFile(file) {
        const formData = new FormData();
        formData.append('fileBase64', file);

        let promise = axios.post(`${globalConfig.api_URL}api/private/uploadfacsimile`, formData, {
            objectResponse: true,
            headers: { 'Content-Type': 'multipart/form-data' }
        });
        return from(promise);
    }
}

export default new MediaService();